import React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby';
import SEO from "../components/seo";
import ContactForm from "../components/contact";
import Map from "../components/map";

class Offer extends React.Component {
    render() {
        const { data } = this.props;
        const siteTitle = data.site.siteMetadata.title;

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title="Kontakt i współpraca" />

                <div className="container mx-auto">
                    <div>
                        <ContactForm />
                    </div>
                </div>

                <Map />
                <div>

                </div>

            </Layout>
        )
    }
}

export default Offer;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
}`