import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from '../components/marker';

const Map = (props) => {
    const getMapOptions = () => {
        return {
            styles: [{
                elementType: "labels.text.fill",
                featureType: "administrative",
                stylers: [{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#46bcec"},{"visibility":"on"}]}]
        }
    };

    return (
        <div className={props.mapClass}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDNyLbkbi9QssGJTysP_eePl_D1HLIS_6s' }}
                defaultCenter={props.center}
                defaultZoom={props.zoom}
                options={getMapOptions}
            >
                <Marker
                    lat={50.685121}
                    lng={21.740732}
                    name="Gabinet lekarski LENSMED Sandomierz"
                />
            </GoogleMapReact>
        </div>
    );
};

Map.defaultProps = {
    center: {
        lat:50.685121,
        lng:21.740732
    },
    zoom: 13,
    mapClass: 'map'
};

export default Map;