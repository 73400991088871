import React  from 'react';

const Marker = (props) => {
    const { color, name, id } = props;
    return (
        <div>
            <div className="marker"
                style={{ backgroundColor: color, cursor: 'pointer'}}
                title={name}
                id={id}
            />
        </div>
    );
  };

  export default Marker;