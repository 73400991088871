import React from "react"
import { Link } from 'gatsby';

class ContactForm extends React.Component {
    render() {
        return (
            <div className="md:flex items-center justify-between py-6 sm:py-8 md:py-24">
                <div className="md:w-7/12 md:pr-16 lg:pr-24 xl:pr-32">
                    <span className="text-main text-xs leading-none tracking-wide uppercase font-bold">KONTAKT</span>
                    <h1 className="font-bold text-3xl">Skontaktuj się z nami</h1>
                    <p className="text-gray-800">
                        W razie jakichkolwiek pytań - jesteśmy do Twojej dyspozycji. Napisz, lub zadzwoń do nas, aby dowiedzieć się więcej.
                    </p>
                    <form name="kontakt" method="POST" netlify-honeypot="bot-field" data-netlify="true">
                        <input type="hidden" name="bot-field" />
                        <input type="hidden" name="form-name" value="kontakt" />
                        <div>
                            <label htmlFor="name" className="label mt-6">Imię i nazwisko:</label>
                            <input id="name" placeholder="Podaj swoje imię" className="input text-base" type="text" name="name" />
                        </div>
                        <div>
                            <label htmlFor="email" className="label mt-6">Adres email:</label>
                            <input id="email" placeholder="Podaj swoje imię" className="input text-base" type="email" name="email" />
                        </div>
                        <div>
                            <label htmlFor="phone" className="label mt-6">Telefon:</label>
                            <input id="phone" placeholder="Podaj swój telefon" className="input text-base" type="text" name="phone" />
                        </div>
                        <div>
                            <label htmlFor="message" className="label mt-6">Wiadomość:</label>
                            <textarea id="message" placeholder="Podaj swoje imię" className="input text-base" name="message" />
                        </div>
                        <div className="mt-2 items-center">
                            <button className="mt-6 btn btn-main px-6 py-3 font-semibold block w-full sm:inline-block sm:w-auto text-center">Skontaktuj się</button>
                            <Link to="/polityka-prywatnosci"
                               className="px-8 font-semibold block w-full sm:inline-block sm:w-auto text-center mt-4 sm:mt-0 underline">
                                Polityka prywatności</Link>
                        </div>
                    </form>
                </div>
                <div className="md:w-5/12">
                    <div>
                        <div className="bg-white shadow-rounded px-12 py-5 flex items-center mt-8 lg:mt-0">
                            <div>
                                <svg className="h-8 w-8 fill-current text-main mr-8" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.22792 3.68377L8.27924 4L8.27924 4L9.22792 3.68377ZM10.7257 8.17721L9.77705 8.49344L10.7257 8.17721ZM10.2243 9.38787L10.6715 10.2823L10.6715 10.2823L10.2243 9.38787ZM7.96701 10.5165L7.51979 9.62207C7.03975 9.86209 6.83479 10.4383 7.05538 10.9275L7.96701 10.5165ZM13.4835 16.033L13.0725 16.9446C13.5618 17.1652 14.1379 16.9602 14.3779 16.4802L13.4835 16.033ZM14.6121 13.7757L13.7177 13.3285L14.6121 13.7757ZM15.8228 13.2743L15.5066 14.2229L15.5066 14.2229L15.8228 13.2743ZM20.3162 14.7721L20.6325 13.8234L20.3162 14.7721ZM5 2C3.34315 2 2 3.34315 2 5H4C4 4.44772 4.44772 4 5 4V2ZM8.27924 2H5V4H8.27924V2ZM10.1766 3.36755C9.90438 2.55086 9.1401 2 8.27924 2V4L8.27924 4L10.1766 3.36755ZM11.6744 7.86098L10.1766 3.36754L8.27924 4L9.77705 8.49344L11.6744 7.86098ZM10.6715 10.2823C11.5617 9.83718 11.9892 8.80521 11.6744 7.86098L9.77705 8.49344L9.77705 8.49344L10.6715 10.2823ZM8.41422 11.4109L10.6715 10.2823L9.77705 8.49344L7.51979 9.62207L8.41422 11.4109ZM13.8945 15.1214C11.6721 14.1194 9.88063 12.3279 8.87863 10.1055L7.05538 10.9275C8.25786 13.5946 10.4054 15.7421 13.0725 16.9446L13.8945 15.1214ZM13.7177 13.3285L12.5891 15.5858L14.3779 16.4802L15.5066 14.2229L13.7177 13.3285ZM16.139 12.3256C15.1948 12.0108 14.1628 12.4383 13.7177 13.3285L15.5066 14.2229L15.5066 14.2229L16.139 12.3256ZM20.6325 13.8234L16.139 12.3256L15.5066 14.2229L20 15.7208L20.6325 13.8234ZM22 15.7208C22 14.8599 21.4491 14.0956 20.6325 13.8234L20 15.7208H22ZM22 19V15.7208H20V19H22ZM19 22C20.6569 22 22 20.6569 22 19H20C20 19.5523 19.5523 20 19 20V22ZM18 22H19V20H18V22ZM2 6C2 14.8366 9.16344 22 18 22V20C10.268 20 4 13.732 4 6H2ZM2 5V6H4V5H2Z"/>
                                </svg>
                            </div>
                            <div>
                                <span className="text-main text-xs leading-none tracking-wide uppercase font-bold">TELEFON</span>
                                <h5 className="font-bold">15 832 95 35</h5>
                                <p className="text-gray-700 text-xs">Dostępność: 8:30-17:30</p>
                            </div>
                        </div>
                        <div className="bg-white shadow-rounded px-12 py-5 flex mt-8 items-center">
                            <div>
                                <svg className="h-8 w-8 mr-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.6569 16.6569C16.7202 17.5935 14.7616 19.5521 13.4138 20.8999C12.6327 21.681 11.3677 21.6814 10.5866 20.9003C9.26234 19.576 7.34159 17.6553 6.34315 16.6569C3.21895 13.5327 3.21895 8.46734 6.34315 5.34315C9.46734 2.21895 14.5327 2.21895 17.6569 5.34315C20.781 8.46734 20.781 13.5327 17.6569 16.6569Z" stroke="#f58220" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M15 11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11Z" stroke="#f58220" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div>
                                <span className="text-main text-xs leading-none tracking-wide uppercase font-bold">ADRES</span>
                                <h5 className="font-bold">Mickiewicza 15, Sandomierz</h5>
                                <p className="text-gray-700 text-xs">II piętro</p>
                            </div>
                        </div>
                        <div className="bg-white shadow-rounded px-12 py-5 flex mt-8 items-center">
                            <div>
                                <svg className="h-8 w-8 mr-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 8L10.8906 13.2604C11.5624 13.7083 12.4376 13.7083 13.1094 13.2604L21 8M5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19Z" stroke="#f58220" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div>
                                <span className="text-main text-xs leading-none tracking-wide uppercase font-bold">E-MAIL</span>
                                <h5 className="font-bold">recepcja@lens-med.pl</h5>
                                <p className="text-gray-700 text-xs">Szybka odpowiedź</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactForm;
